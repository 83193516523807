import React from 'react';

import { Link } from 'gatsby';
import { ModalRoutingContext } from 'gatsby-plugin-modal-routing-3';

import SEO from '../components/seo';

import styles from '../styles/pages/region.module.scss';
import { pageLinks } from '../helpers/navigation';
import { getInstanceName } from '../helpers/utils';
import LocaleTabs from "../components/locale-tabs";

const RegionsPage = () => {
  const siteName = getInstanceName();
  const pleaseChooseTxt = 'Please choose your region';
  const closeTxt = 'close';
  const title = 'Regions';

  return (
    <ModalRoutingContext.Consumer>
      {({ modal, closeTo }) => (
        <>
          <SEO title={`${title} - ${siteName}`} />
          <div className="container">
            <div className={styles.regions}>
              <div className={styles.header}>
                <div className={styles.closeLink}>
                  <Link to={modal ? closeTo : pageLinks.home} className={styles.link}>
                    <span>{closeTxt}</span>
                    <i className="icon-close" />
                  </Link>
                </div>
              </div>
              <h2 className={styles.title}>{pleaseChooseTxt}</h2>
              <div className={styles.tabsWrapper}>
                <LocaleTabs closeTo={closeTo} />
              </div>
            </div>
          </div>
        </>
      )}
    </ModalRoutingContext.Consumer>
  );
};

export default RegionsPage;
