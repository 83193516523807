import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';

import styles from './language-list-item.module.scss';

const LanguageListItem = ({
  className,
  component: Component,
  name,
  flag,
  onCountrySelect,
  isActive,
}) => (
    <Component
      className={clsx(styles.listItem, className, isActive && 'active')}
      onClick={onCountrySelect}
    >
      <div className={styles.wrapper}>
        <div className={styles.imageWrapper}>{flag}</div>
        <div className={styles.textContent}>
          <span className={styles.country}>{name}</span>
        </div>
      </div>
    </Component>
  );

LanguageListItem.defaultProps = {
  component: 'li',
  className: '',
};

LanguageListItem.propTypes = {
  component: PropTypes.string,
  className: PropTypes.string,
  name: PropTypes.string.isRequired,
  flag: PropTypes.string.isRequired,
  isActive: PropTypes.bool.isRequired,
  onCountrySelect: PropTypes.func.isRequired,
};

export default LanguageListItem;
